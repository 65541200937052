import React, { useState, useEffect, useCallback } from 'react';
import classes from './PriceList.module.scss';
import get from '../../../../services/axios/get';
import Loader from '../../../UI/Loader/Loader';
import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';
import DatePicker from '../../../UI/DatePicker/DatePicker';
import Modal from '../../../../containers/Modal/Modal';
import put from '../../../../services/axios/put';
import Alert from '../../../UI/Alert/Alert';

interface PriceListProps {
  clientName: string;
  clientId: string;
}

interface LoadPoint {
  id: string;
  alias: string;
}

interface PriceItems {
  [loadPointId: string]: number;
  fuelLevyPercentage: number;
}

const getFormattedDate = (date: Date) => date.getFullYear() +
  '-' +
  String(date.getMonth() + 1).padStart(2, '0') +
  '-' +
  String(date.getDate()).padStart(2, '0');

const PriceList: React.FC<PriceListProps> = ({ clientName, clientId }) => {
  const [fuelLevyPercentage, setFuelLevyPercentage] = useState<number>(0);
  const [loadPoints, setLoadPoints] = useState<LoadPoint[]>([]);
  const [priceItems, setPriceItems] = useState<any>({
    fuelLevyPercentage: 0,
    additionalCharges: {
      ctoFee: 0,
      preAdvisedFee: 0,
      genset: 0,
      overnightGenset: 0,
      dualLoadOnRoute: 0,
      dualLoadOffRoute: 0,
      weighbridgeSurcharge: 0,
      satlWeighbridge: 0,
      crossHaul: 0,
      belconPickup: 0
    }
  });
  const [isLoading, setIsLoading] = useState(true);
  const [priceListsByDate, setPriceListsByDate] = useState<Record<string, PriceItems>>({});
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [isPriceListAvailable, setIsPriceListAvailable] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);

  const getLoadPoints = useCallback(() => {
    get('/load-point', { isDischarge: false }, (res) => {
      setLoadPoints(res.data);
      setIsLoading(false);
    });
  }, []);

  const getPriceLists = useCallback(() => {
    setIsLoading(true);
    setSelectedDate('');
    setPriceListsByDate({});

    get(`/price-list/${clientId}`, {}, (res) => {
      const data: Record<string, PriceItems> = res.data;

      delete data['clientName']

      setPriceListsByDate(data);

      const dates = Object.keys(data).sort();
      if (dates.length > 0) {
        const latestDate = dates[dates.length - 1];
        setSelectedDate(latestDate);
        setPriceItems(data[latestDate]);
        setFuelLevyPercentage(data[latestDate].fuelLevyPercentage || 0);
        setIsPriceListAvailable(true);
      } else {
        setSelectedDate('');
        setIsPriceListAvailable(false);
      }
      setIsLoading(false);
    }, (error: any) => {
      if (error === 'Not Found') {
        setIsPriceListAvailable(false);
      }
      setIsLoading(false);
    });
  }, [clientId]);

  const createNewPriceList = (date: string) => {
    const dates = Object.keys(priceListsByDate);
    const latestDate = dates.length > 0 ? dates[dates.length - 1] : '';
    const newPriceList = latestDate ? { ...priceListsByDate[latestDate] } : { fuelLevyPercentage: 0 };

    const updatedPriceLists = {
      ...priceListsByDate,
      [date]: newPriceList,
    };

    put(`/price-list/${clientId}`, { ...updatedPriceLists, clientName }, (res) => {
      setPriceListsByDate(updatedPriceLists);
      setSelectedDate(date);
      setPriceItems(newPriceList);
      setFuelLevyPercentage(newPriceList.fuelLevyPercentage || 0);
      setIsPriceListAvailable(true);
    });
  };

  const openDateModal = () => setShowDateModal(true);
  const closeDateModal = () => setShowDateModal(false);

  useEffect(() => {
    getLoadPoints();
    getPriceLists();
  }, [getLoadPoints, getPriceLists]);

  useEffect(() => {
    getPriceLists();
  }, [clientId, getPriceLists]);

  const handleBaseRateChange = (loadPointId: string, value: number) => {
    setPriceItems((prevItems: any) => ({ ...prevItems, [loadPointId]: value }));
  };

  const handleFuelLevyChange = (value: string) => {
    const numericValue = parseFloat(value);
    setFuelLevyPercentage(!isNaN(numericValue) ? numericValue : 0);
    setPriceItems((prevItems: any) => ({ ...prevItems, fuelLevyPercentage: numericValue }));
  };

  const handleAdditionalChargeChange = (chargeName: string, value: string) => {
    const numericValue = parseFloat(value.split(' ')[1] || '0');
    setPriceItems((prevItems: any) => ({
      ...prevItems,
      additionalCharges: {
        ...prevItems.additionalCharges,
        [chargeName]: !isNaN(numericValue) ? numericValue : 0
      }
    }));
  };

  const savePriceList = () => {
    const updatedPriceLists = {
      ...priceListsByDate,
      [selectedDate]: { ...priceItems, fuelLevyPercentage }
    };

    put(`/price-list/${clientId}`, { ...updatedPriceLists, clientName }, () => {
      setPriceListsByDate(updatedPriceLists);
    });
  };

  const handleDateSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const date = event.target.value;
    setSelectedDate(date);
    const selectedPriceItems = priceListsByDate[date] || {};
    setPriceItems(selectedPriceItems);
    setFuelLevyPercentage(selectedPriceItems.fuelLevyPercentage || 0);
  };

  return (
    <div className={classes['price-list']}>
      {isLoading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={classes['date-selection']}>
            {selectedDate === '' || selectedDate == null ? <div></div> : (
              <select className={classes['date-dropdown']} value={selectedDate} onChange={handleDateSelection}>
                {Object.keys(priceListsByDate)
                  .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
                  .map((date) => (
                    <option key={date} value={date}>
                      {date}
                    </option>
                  ))}
              </select>
            )}
            <Button
              buttonStyle='main'
              type='button'
              text='Create New Price List'
              click={openDateModal}
            />
          </div>

          {isPriceListAvailable && (
            <>
              <div className={classes['fuel-levy']}>
                <label htmlFor="fuel-levy">Fuel Levy %:</label>
                <Input
                  elementType={'input'}
                  elementConfig={{
                    type: 'number',
                    step: '1',
                    min: '-100'
                  }}
                  value={fuelLevyPercentage.toString()}
                  change={(e: React.ChangeEvent<HTMLInputElement>) => handleFuelLevyChange(e.target.value)}
                  id={'fuel-levy'}
                  labelHidden
                  inputStyle='main'
                />
              </div>

              <table className={classes['price-table']}>
                <thead>
                  <tr>
                    <th>Load Point</th>
                    <th>Base Rate</th>
                    <th>Fuel Levy %</th>
                  </tr>
                </thead>
                <tbody>
                  {loadPoints.map((loadPoint) => (
                    <tr key={loadPoint.alias}>
                      <td>{loadPoint.alias}</td>
                      <td>
                        <Input
                          elementType={'currency'}
                          elementConfig={{
                            placeholder: 'R 0.00',
                          }}
                          value={`R ${(priceItems[loadPoint.alias])?.toString()}` ?? "R 0.00"}
                          change={(e) => handleBaseRateChange(loadPoint.alias, parseFloat((e.target.value ?? '').split(' ')[1]))}
                          id='amount-input'
                          inputStyle='main'
                          labelHidden
                          confirm={() => setShowSaveAlert(true)}
                          confirmOnBlur={false}
                        />
                      </td>
                      <td>
                        {priceItems[loadPoint.alias]
                          ? `R ${(priceItems[loadPoint.alias] * (1 + fuelLevyPercentage / 100)).toFixed(2)}`
                          : ''}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <h3>Additional Charges</h3>
              <table className={classes['price-table']}>
                <thead>
                  <tr>
                    <th>Charge Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CTO Fee</td>
                    <td>
                      <Input
                        elementType={'currency'}
                        elementConfig={{
                          placeholder: 'R 0.00',
                        }}
                        value={`R ${priceItems.additionalCharges?.ctoFee?.toString() ?? '0.00'}`}
                        change={(e) => handleAdditionalChargeChange('ctoFee', e.target.value)}
                        id='cto-fee-input'
                        inputStyle='main'
                        labelHidden
                        confirm={() => setShowSaveAlert(true)}
                        confirmOnBlur={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Pre Advised Fee</td>
                    <td>
                      <Input
                        elementType={'currency'}
                        elementConfig={{
                          placeholder: 'R 0.00',
                        }}
                        value={`R ${priceItems.additionalCharges?.preAdvisedFee?.toString() ?? '0.00'}`}
                        change={(e) => handleAdditionalChargeChange('preAdvisedFee', e.target.value)}
                        id='pre-advised-fee-input'
                        inputStyle='main'
                        labelHidden
                        confirm={() => setShowSaveAlert(true)}
                        confirmOnBlur={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Genset</td>
                    <td>
                      <Input
                        elementType={'currency'}
                        elementConfig={{
                          placeholder: 'R 0.00',
                        }}
                        value={`R ${priceItems.additionalCharges?.genset?.toString() ?? '0.00'}`}
                        change={(e) => handleAdditionalChargeChange('genset', e.target.value)}
                        id='genset-input'
                        inputStyle='main'
                        labelHidden
                        confirm={() => setShowSaveAlert(true)}
                        confirmOnBlur={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Overnight Genset</td>
                    <td>
                      <Input
                        elementType={'currency'}
                        elementConfig={{
                          placeholder: 'R 0.00',
                        }}
                        value={`R ${priceItems.additionalCharges?.overnightGenset?.toString() ?? '0.00'}`}
                        change={(e) => handleAdditionalChargeChange('overnightGenset', e.target.value)}
                        id='overnight-genset-input'
                        inputStyle='main'
                        labelHidden
                        confirm={() => setShowSaveAlert(true)}
                        confirmOnBlur={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Dual Load (on route)</td>
                    <td>
                      <Input
                        elementType={'currency'}
                        elementConfig={{
                          placeholder: 'R 0.00',
                        }}
                        value={`R ${priceItems.additionalCharges?.dualLoadOnRoute?.toString() ?? '0.00'}`}
                        change={(e) => handleAdditionalChargeChange('dualLoadOnRoute', e.target.value)}
                        id='dual-load-on-route-input'
                        inputStyle='main'
                        labelHidden
                        confirm={() => setShowSaveAlert(true)}
                        confirmOnBlur={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Dual Load (off route)</td>
                    <td>
                      <Input
                        elementType={'currency'}
                        elementConfig={{
                          placeholder: 'R 0.00',
                        }}
                        value={`R ${priceItems.additionalCharges?.dualLoadOffRoute?.toString() ?? '0.00'}`}
                        change={(e) => handleAdditionalChargeChange('dualLoadOffRoute', e.target.value)}
                        id='dual-load-off-route-input'
                        inputStyle='main'
                        labelHidden
                        confirm={() => setShowSaveAlert(true)}
                        confirmOnBlur={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Weighbridge Surcharge</td>
                    <td>
                      <Input
                        elementType={'currency'}
                        elementConfig={{
                          placeholder: 'R 0.00',
                        }}
                        value={`R ${priceItems.additionalCharges?.weighbridgeSurcharge?.toString() ?? '0.00'}`}
                        change={(e) => handleAdditionalChargeChange('weighbridgeSurcharge', e.target.value)}
                        id='weighbridge-surcharge-input'
                        inputStyle='main'
                        labelHidden
                        confirm={() => setShowSaveAlert(true)}
                        confirmOnBlur={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>SATL Weighbridge</td>
                    <td>
                      <Input
                        elementType={'currency'}
                        elementConfig={{
                          placeholder: 'R 0.00',
                        }}
                        value={`R ${priceItems.additionalCharges?.satlWeighbridge?.toString() ?? '0.00'}`}
                        change={(e) => handleAdditionalChargeChange('satlWeighbridge', e.target.value)}
                        id='satl-weighbridge-input'
                        inputStyle='main'
                        labelHidden
                        confirm={() => setShowSaveAlert(true)}
                        confirmOnBlur={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Cross Haul</td>
                    <td>
                      <Input
                        elementType={'currency'}
                        elementConfig={{
                          placeholder: 'R 0.00',
                        }}
                        value={`R ${priceItems.additionalCharges?.crossHaul?.toString() ?? '0.00'}`}
                        change={(e) => handleAdditionalChargeChange('crossHaul', e.target.value)}
                        id='cross-haul-input'
                        inputStyle='main'
                        labelHidden
                        confirm={() => setShowSaveAlert(true)}
                        confirmOnBlur={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Belcon/ICR/Milltrans Pick Up</td>
                    <td>
                      <Input
                        elementType={'currency'}
                        elementConfig={{
                          placeholder: 'R 0.00',
                        }}
                        value={`R ${priceItems.additionalCharges?.belconPickup?.toString() ?? '0.00'}`}
                        change={(e) => handleAdditionalChargeChange('belconPickup', e.target.value)}
                        id='belcon-pickup-input'
                        inputStyle='main'
                        labelHidden
                        confirm={() => setShowSaveAlert(true)}
                        confirmOnBlur={false}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className={classes.footer}>
                <Button
                  buttonStyle='main'
                  type='button'
                  text='Save'
                  click={() => setShowSaveAlert(true)}
                />
              </div>
            </>
          )}

          <Alert
            confirm={(confirmation) => {
              if (confirmation) {
                savePriceList();
              }
              setShowSaveAlert(false);
            }}
            message={<div>Are you sure you want to save this price list?</div>}
            isOpen={showSaveAlert}
            isConfirm
          />

          {showDateModal && (
            <Modal close={closeDateModal} style={{ width: '400px' }} stacked={false} stretch>
              <div>Select a Date for New Price List</div>
              <DatePicker
                defaultDate={new Date()}
                callback={(date, isValid) => {
                  if (isValid && date !== null) {
                    const formattedDate = getFormattedDate(date);
                    createNewPriceList(formattedDate);
                    closeDateModal();
                  }
                }}
                invalidMessage='Invalid date'
              />
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default PriceList;
