import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../UI/Button/Button';
import classes from './CreateNewLoad.module.scss'
import Input from '../../UI/Input/Input';
import checkValidity from '../../../helpers/checkValidity';
import get from '../../../services/axios/get';
import DatePicker from '../../UI/DatePicker/DatePicker';
import { useAppDispatch } from '../../../store/hooks';
import { customAlert } from '../../../store/actions/alert';
import Load from '../../../models/load';
import Loader from '../../UI/Loader/Loader';
import Driver from '../../../models/driver';

// TODO: Consolidate the create modal with the edit modal

interface CreateNewLoadProps {
  close: () => void;
  createLoad: (load: Load, onError: () => void) => void;
}
interface FormItem {
  value: string;
  isValid: boolean;
  isTouched: boolean;
  date?: Date;
}
interface Form {
  [key: string]: FormItem;
}

interface Entity {
  alias: string;
  id: string;
  isDischarge?: boolean;
}

const initialForm: Form = {
  client: { value: '', isValid: false, isTouched: false },
  emptyDepot: { value: '', isValid: false, isTouched: false },
  temptaleType: { value: '', isValid: false, isTouched: false },
  port: { value: '', isValid: false, isTouched: false },
  temptaleSupplier: { value: '', isValid: false, isTouched: false },
  loadPoint1: { value: '', isValid: false, isTouched: false },
  loadPoint2: { value: '', isValid: false, isTouched: false },
  dischargePort: { value: '', isValid: false, isTouched: false },
  isGenset: { value: '', isValid: false, isTouched: false },
  vessel: { value: '', isValid: false, isTouched: false },
  commodity: { value: '', isValid: false, isTouched: false },
  tempCode: { value: '', isValid: false, isTouched: false },
  shippingLine: { value: '', isValid: false, isTouched: false },
  weighMethod: { value: '', isValid: false, isTouched: false },
  ventType: { value: '', isValid: false, isTouched: false },
  loadDate: { value: '', isValid: false, isTouched: false },
  containerNumber: { value: '', isValid: false, isTouched: false },
  voyage: { value: '', isValid: false, isTouched: false },
  sealNumber: { value: '', isValid: false, isTouched: false },
  exporterRef: { value: '', isValid: false, isTouched: false },
  bookingRef: { value: '', isValid: false, isTouched: false },
  clientRef: { value: '', isValid: false, isTouched: false },
  truckReg: { value: '', isValid: false, isTouched: false },
  additionalInfo: { value: '', isValid: false, isTouched: false },
  driverName: { value: '', isValid: false, isTouched: false },
  driverPhoneNumber: { value: '', isValid: false, isTouched: false },
  driverId: { value: '', isValid: false, isTouched: false },
  tempSetting: { value: '', isValid: false, isTouched: false },
  loadTime: { value: '', isValid: false, isTouched: false },
  bookingTime: { value: '', isValid: false, isTouched: false },
  container: { value: '', isValid: false, isTouched: false },
  status: { value: '', isValid: false, isTouched: false },
  temptaleNumber: { value: '', isValid: false, isTouched: false },
}

const CreateNewLoad: React.FC<CreateNewLoadProps> = (props: CreateNewLoadProps) => {
  const isMounted = useRef(true);
  const [formValues, setFormValues] = useState(initialForm)
  const dispatch = useAppDispatch();

  let defaultLoadDate = new Date();
  let maxLoadDate = new Date();
  let minLoadDate = new Date();

  defaultLoadDate.setMonth(defaultLoadDate.getMonth());
  maxLoadDate.setMonth(maxLoadDate.getMonth() + 24);
  minLoadDate.setMonth(minLoadDate.getMonth() - 6);

  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients]: [
    { clients: Entity[], isLoading: boolean },
    (clients: any) => any
  ] = useState({ clients: [], isLoading: true });
  const [emptyDepots, setEmptyDepots]: [
    { emptyDepots: Entity[], isLoading: boolean },
    (emptyDepots: any) => any
  ] = useState({ emptyDepots: [], isLoading: true });
  const [temptaleTypes, setTemptaleTypes]: [
    { temptaleTypes: Entity[], isLoading: boolean },
    (temptaleTypes: any) => any
  ] = useState({ temptaleTypes: [], isLoading: true });
  const [ports, setPorts]: [
    { ports: Entity[], isLoading: boolean },
    (ports: any) => any
  ] = useState({ ports: [], isLoading: true });
  const [temptaleSuppliers, setTemptaleSuppliers]: [
    { temptaleSuppliers: Entity[], isLoading: boolean },
    (temptaleSuppliers: any) => any
  ] = useState({ temptaleSuppliers: [], isLoading: true });
  const [loadPoints, setLoadPoints]: [
    { loadPoints: Entity[], isLoading: boolean },
    (loadPoints: any) => any
  ] = useState({ loadPoints: [], isLoading: true });
  const [dischargePorts, setDischargePorts]: [
    { ports: Entity[], isLoading: boolean },
    (ports: any) => any
  ] = useState({ ports: [], isLoading: true });
  const [vessels, setVessels]: [
    { vessels: Entity[], isLoading: boolean },
    (vessels: any) => any
  ] = useState({ vessels: [], isLoading: true });
  const [commodities, setCommodities]: [
    { commodities: Entity[], isLoading: boolean },
    (commodities: any) => any
  ] = useState({ commodities: [], isLoading: true });
  const [tempCodes, setTempCodes]: [
    { tempCodes: Entity[], isLoading: boolean },
    (tempCodes: any) => any
  ] = useState({ tempCodes: [], isLoading: true });
  const [shippingLines, setShippingLines]: [
    { shippingLines: Entity[], isLoading: boolean },
    (shippingLines: any) => any
  ] = useState({ shippingLines: [], isLoading: true });
  const [weighMethods, setWeighMethods]: [
    { weighMethods: Entity[], isLoading: boolean },
    (weighMethods: any) => any
  ] = useState({ weighMethods: [], isLoading: true });
  const [ventTypes, setVentTypes]: [
    { ventTypes: Entity[], isLoading: boolean },
    (ventTypes: any) => any
  ] = useState({ ventTypes: [], isLoading: true });
  const [tempSettings, setTempSettings]: [
    { tempSettings: Entity[], isLoading: boolean },
    (tempSettings: any) => any
  ] = useState({ tempSettings: [], isLoading: true });
  const [containers, setContainers]: [
    { containers: Entity[], isLoading: boolean },
    (containers: any) => any
  ] = useState({ containers: [], isLoading: true });
  const [clientRefExists, setClientRefExists] = useState<boolean>(false);

  const parseForm = (): Load | null => {
    if (formValues.client.value.trim() === '' || formValues.client.value == null) {
      dispatch(customAlert(true, 'Please select a client'));
      return null;
    }

    const form: Partial<Load> = {};

    Object.keys(formValues).forEach((key) => {
      const loadKey = key as keyof Load;
      let value = formValues[loadKey].value;

      if ((typeof value) === 'string') {
        value = value.trim();
      }

      if (loadKey === 'isGenset') {
        form[loadKey] = formValues.isGenset.value.trim().toLowerCase() === 'yes'
      } else if (loadKey === 'preAdvised') {
        form[loadKey] = formValues.preAdvised.value.trim().toLowerCase() === 'yes'
      } else if (loadKey === 'hasBookingPhoto') {
        form[loadKey] = false;
      }

      else if (value !== '' &&
        value != null &&
        loadKey !== 'loadStatus' &&
        loadKey !== 'charges' &&
        loadKey !== 'driver'
      ) {
        form[loadKey] = value;
      }
    });

    if (formValues.driverName.value != null && formValues.driverName.value !== '') {
      form['driver'] = {
        id: formValues.driverId.value,
        alias: formValues.driverName.value
      }

      if (formValues.driverPhoneNumber.value !== '') {
        form.driver.phoneNumber = formValues.driverPhoneNumber.value
      }
    }

    form['loadStatus'] = formValues.driverName.value.trim() !== '' ? 'assigned' : 'unassigned'

    return form as Load;
  };

  const createLoad = () => {
    const load: Load | null = parseForm()

    if (load != null) {
      setIsLoading(true);

      props.createLoad(load, () => setIsLoading(false));
    }
  }

  const checkIfClientRefExists = useCallback(
    (clientRef: string) => {
      get(
        '/load?clientRef=' + clientRef,
        {},
        (res) => {
          if (isMounted) {
            if (res.data.length > 0) {
              dispatch(customAlert(true, `A load with client ref ${clientRef} already exists`))
              setClientRefExists(true);
            } else {
              setClientRefExists(false);
            }
          }
        },
        () => { });
    },
    []
  );

  const getTempSettings = useCallback(
    () => {
      get(
        '/temp-setting',
        {},
        (res) => {
          if (isMounted) {
            setTempSettings({ tempSettings: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getClients = useCallback(
    () => {
      get(
        '/client',
        {},
        (res) => {
          if (isMounted) {
            setClients({ clients: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getEmptyDepots = useCallback(
    () => {
      get(
        '/depot',
        {},
        (res) => {
          if (isMounted) {
            setEmptyDepots({ emptyDepots: res.data, isLoading: false });
          }
        },
        () => { });
    },
    []
  );

  const getTemptaleTypes = useCallback(
    () => {
      get(
        '/temp-tale-type',
        {},
        (res) => {
          if (isMounted) {
            setTemptaleTypes({ temptaleTypes: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getPorts = useCallback(
    () => {
      get(
        '/port',
        {},
        (res) => {
          if (isMounted) {
            const dischargePorts: Entity[] = [];
            const ports: Entity[] = [];

            res.data.forEach((port: Entity) => {
              if (port.isDischarge) {
                dischargePorts.push(port);
              } else {
                ports.push(port);
              }
            })
            setPorts({ ports: ports, isLoading: false });
            setDischargePorts({ ports: dischargePorts, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getTemptaleSuppliers = useCallback(
    () => {
      get(
        '/temp-tale-supplier',
        {},
        (res) => {
          if (isMounted) {
            setTemptaleSuppliers({ temptaleSuppliers: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getLoadPoints = useCallback(
    () => {
      get(
        '/load-point',
        {},
        (res) => {
          if (isMounted) {
            setLoadPoints({ loadPoints: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getVessels = useCallback(
    () => {
      get(
        '/vessel',
        {},
        (res) => {
          if (isMounted) {
            setVessels({ vessels: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getCommodities = useCallback(
    () => {
      get(
        '/commodity',
        {},
        (res) => {
          if (isMounted) {
            setCommodities({ commodities: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getTempCodes = useCallback(
    () => {
      get(
        '/temp-code',
        {},
        (res) => {
          if (isMounted) {
            setTempCodes({ tempCodes: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getShippingLines = useCallback(
    () => {
      get(
        '/shipping-line',
        {},
        (res) => {
          if (isMounted) {
            setShippingLines({ shippingLines: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getWeighMethods = useCallback(
    () => {
      get(
        '/weigh-method',
        {},
        (res) => {
          if (isMounted) {
            setWeighMethods({ weighMethods: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getVents = useCallback(
    () => {
      get(
        '/vent-type',
        {},
        (res) => {
          if (isMounted) {
            setVentTypes({ ventTypes: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );

  const getContainers = useCallback(
    () => {
      get(
        '/container',
        {},
        (res) => {
          if (isMounted) {
            setContainers({ containers: res.data, isLoading: false });
          }
        },
        () => { }
      );
    },
    []
  );


  const updateForm = (value: string, identifier: string, date?: Date) => {
    if (identifier === 'clientRef') {
      setClientRefExists(false)

      if (value.length > 4) {
        checkIfClientRefExists(value)
      }
    }

    setFormValues(prevState => {
      let newForm: Form = { ...prevState };
      let newItem: FormItem = { ...newForm[identifier] }

      if (typeof value === 'string' && value.length >= 1) {
        newItem.value = value.trim();
      } else {
        newItem.value = value;
      }

      newItem.isTouched = true;

      if (typeof value === 'string') {
        newItem.isValid = checkValidity(value, {
          required: true,
          minLength: 2,
          maxLength: 40,
          isEmail: identifier === 'email' ? true : false
        });
      } else {
        newItem.isValid = true
      }

      if (date != null) {
        newItem.date = date;
      }

      newForm[identifier] = newItem;

      return newForm;
    })
  };

  useEffect(() => {
    getClients();
    getEmptyDepots();
    getTemptaleTypes();
    getPorts();
    getTemptaleSuppliers();
    getLoadPoints();
    getVessels();
    getCommodities();
    getTempCodes();
    getShippingLines();
    getWeighMethods();
    getVents();
    getTempSettings();
    getContainers();

    return () => {
      isMounted.current = false;
    }
  }, [
    getClients,
    getEmptyDepots,
    getTemptaleTypes,
    getPorts,
    getTemptaleSuppliers,
    getLoadPoints,
    getVessels,
    getCommodities,
    getTempCodes,
    getShippingLines,
    getWeighMethods,
    getVents,
    getTempSettings,
    getContainers,
  ])

  return (
    <div className={classes['create-new-load']}>
      <h6>Create New Load</h6>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          createLoad();
        }}
      >
        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Client',
              options: [

                ...clients.clients.map(
                  client => ({ value: client.alias, displayValue: client.alias }))
              ]
            }}
            isLoading={clients.isLoading}
            value={formValues.client.value}
            invalid={!formValues.client.isValid}
            shouldValidate
            touched={formValues.client.isTouched}
            change={(e) => updateForm(e.target.value, 'client')}
            id='client-input'
            label='Client'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Load Point 1',
              options: [

                ...loadPoints.loadPoints.map(
                  point => ({ value: point.alias, displayValue: point.alias })
                )
              ]
            }}
            isLoading={loadPoints.isLoading}
            value={formValues.loadPoint1.value}
            invalid={!formValues.loadPoint1.isValid}
            shouldValidate
            touched={formValues.loadPoint1.isTouched}
            change={(e) => updateForm(e.target.value, 'loadPoint1')}
            id='loadPoint-input'
            label='Load Point 1'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Load Point 2',
              options: [

                ...loadPoints.loadPoints.map(
                  point => ({ value: point.alias, displayValue: point.alias })
                )
              ]
            }}
            isLoading={loadPoints.isLoading}
            value={formValues.loadPoint2.value}
            invalid={!formValues.loadPoint2.isValid}
            shouldValidate
            touched={formValues.loadPoint2.isTouched}
            change={(e) => updateForm(e.target.value, 'loadPoint2')}
            id='loadPoint-input'
            label='Load Point 2'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Shipping Line',
              options: [

                ...shippingLines.shippingLines.map(
                  line => ({ value: line.alias, displayValue: line.alias })
                )
              ]
            }}
            isLoading={shippingLines.isLoading}
            value={formValues.shippingLine.value}
            invalid={!formValues.shippingLine.isValid}
            shouldValidate
            touched={formValues.shippingLine.isTouched}
            change={(e) => updateForm(e.target.value, 'shippingLine')}
            id='shippingLine-input'
            label='Shipping Line'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Vessel',
              options: [

                ...vessels.vessels.map(
                  vessel => ({ value: vessel.alias, displayValue: vessel.alias })
                )
              ]
            }}
            isLoading={vessels.isLoading}
            value={formValues.vessel.value}
            invalid={!formValues.vessel.isValid}
            shouldValidate
            touched={formValues.vessel.isTouched}
            change={(e) => updateForm(e.target.value, 'vessel')}
            id='vessel-input'
            label='Vessel'
            inputStyle='main'
          />

          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.voyage.value}
            invalid={!formValues.voyage.isValid}
            shouldValidate
            touched={formValues.voyage.isTouched}
            change={(e) => updateForm(e.target.value, 'voyage')}
            id='voyage-input'
            label='Voyage'
            inputStyle='main'
          />

        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Port',
              options: [

                ...ports.ports.map(
                  port => ({ value: port.alias, displayValue: port.alias })
                )
              ]
            }}
            isLoading={ports.isLoading}
            value={formValues.port.value}
            invalid={!formValues.port.isValid}
            shouldValidate
            touched={formValues.port.isTouched}
            change={(e) => updateForm(e.target.value, 'port')}
            id='port-input'
            label='Port of Load'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Port of Discharge',
              options: [

                ...dischargePorts.ports.map(
                  port => ({ value: port.alias, displayValue: port.alias })
                )
              ]
            }}
            isLoading={dischargePorts.isLoading}
            value={formValues.dischargePort.value}
            invalid={!formValues.dischargePort.isValid}
            shouldValidate
            touched={formValues.dischargePort.isTouched}
            change={(e) => updateForm(e.target.value, 'dischargePort')}
            id='dischargePort-input'
            label='Port of Discharge'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Empty Depot',
              options: [

                ...emptyDepots.emptyDepots.map(
                  emptyDepot => ({ value: emptyDepot.alias, displayValue: emptyDepot.alias }))
              ]
            }}
            isLoading={emptyDepots.isLoading}
            value={formValues.emptyDepot.value}
            invalid={!formValues.emptyDepot.isValid}
            shouldValidate
            touched={formValues.emptyDepot.isTouched}
            change={(e) => updateForm(e.target.value, 'emptyDepot')}
            id='emptyDepot-input'
            label='Empty Depot'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Temp Code',
              options: [

                ...tempCodes.tempCodes.map(
                  code => ({ value: code.alias, displayValue: code.alias })
                )
              ]
            }}
            isLoading={tempCodes.isLoading}
            value={formValues.tempCode.value}
            invalid={!formValues.tempCode.isValid}
            shouldValidate
            touched={formValues.tempCode.isTouched}
            change={(e) => updateForm(e.target.value, 'tempCode')}
            id='tempCode-input'
            label='Temp Code'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Temp Setting',
              options: [
                ...tempSettings.tempSettings.map(
                  tempSetting => ({ value: tempSetting.alias, displayValue: tempSetting.alias }))
              ]
            }}
            isLoading={tempSettings.isLoading}
            value={formValues.tempSetting.value}
            invalid={!formValues.tempSetting.isValid}
            shouldValidate
            touched={formValues.tempSetting.isTouched}
            change={(e) => updateForm(e.target.value, 'tempSetting')}
            id='tempSetting-input'
            label='Temp Setting'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Vent',
              options: [

                ...ventTypes.ventTypes.map(
                  vent => ({ value: vent.alias, displayValue: vent.alias })
                )
              ]
            }}
            isLoading={ventTypes.isLoading}
            value={formValues.ventType.value}
            invalid={!formValues.ventType.isValid}
            shouldValidate
            touched={formValues.ventType.isTouched}
            change={(e) => updateForm(e.target.value, 'ventType')}
            id='vent-input'
            label='Vent'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              displayValue: 'Temptale Supplier',
              options: [

                ...temptaleSuppliers.temptaleSuppliers.map(
                  supplier => ({ value: supplier.alias, displayValue: supplier.alias })
                )
              ]
            }}
            isLoading={temptaleSuppliers.isLoading}
            value={formValues.temptaleSupplier.value}
            invalid={!formValues.temptaleSupplier.isValid}
            shouldValidate
            touched={formValues.temptaleSupplier.isTouched}
            change={(e) => updateForm(e.target.value, 'temptaleSupplier')}
            id='temptaleSupplier-input'
            label='Temptale Supplier'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Temptale Type',
              options: [
                ...temptaleTypes.temptaleTypes.map(
                  type => ({ value: type.alias, displayValue: type.alias }))
              ]
            }}
            isLoading={temptaleTypes.isLoading}
            value={formValues.temptaleType.value}
            invalid={!formValues.temptaleType.isValid}
            shouldValidate
            touched={formValues.temptaleType.isTouched}
            change={(e) => updateForm(e.target.value, 'temptaleType')}
            id='temptaleType-input'
            label='Temptale Type'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Commodity',
              options: [

                ...commodities.commodities.map(
                  commodity => ({ value: commodity.alias, displayValue: commodity.alias })
                )
              ]
            }}
            isLoading={commodities.isLoading}
            value={formValues.commodity.value}
            invalid={!formValues.commodity.isValid}
            shouldValidate
            touched={formValues.commodity.isTouched}
            change={(e) => updateForm(e.target.value, 'commodity')}
            id='commodity-input'
            label='Commodity'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.clientRef.value}
            invalid={!formValues.clientRef.isValid || clientRefExists}
            shouldValidate
            touched={formValues.clientRef.isTouched || clientRefExists}
            change={(e) => updateForm(e.target.value, 'clientRef')}
            id='clientRef-input'
            label='Client Ref.'
            inputStyle='main'
          />

          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.bookingRef.value}
            invalid={!formValues.bookingRef.isValid}
            shouldValidate
            touched={formValues.bookingRef.isTouched}
            change={(e) => updateForm(e.target.value, 'bookingRef')}
            id='bookingRef-input'
            label='Booking Ref.'
            inputStyle='main'
          />

          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.exporterRef.value}
            invalid={!formValues.exporterRef.isValid}
            shouldValidate
            touched={formValues.exporterRef.isTouched}
            change={(e) => updateForm(e.target.value, 'exporterRef')}
            id='exporterRef-input'
            label='Exporter Ref.'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Weigh Method',
              options: [

                ...weighMethods.weighMethods.map(
                  method => ({ value: method.alias, displayValue: method.alias })
                )
              ]
            }}
            isLoading={weighMethods.isLoading}
            value={formValues.weighMethod.value}
            invalid={!formValues.weighMethod.isValid}
            shouldValidate
            touched={formValues.weighMethod.isTouched}
            change={(e) => updateForm(e.target.value, 'weighMethod')}
            id='weighMethod-input'
            label='Weigh Method'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Container Info',
              options: [
                ...containers.containers.map(
                  container => ({ value: container.alias, displayValue: container.alias }))
              ]
            }}
            isLoading={containers.isLoading}
            value={formValues.container.value}
            invalid={!formValues.container.isValid}
            shouldValidate
            touched={formValues.container.isTouched}
            change={(e) => updateForm(e.target.value, 'container')}
            id='container-input'
            label='Container Info'
            inputStyle='main'
          />

          <Input
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Genset',
              options: [

                { displayValue: 'Yes', value: 'Yes', isPlaceholder: false },
                { displayValue: 'No', value: 'No', isPlaceholder: false }
              ]
            }}
            value={formValues.isGenset.value}
            invalid={!formValues.isGenset.isValid}
            shouldValidate
            touched={formValues.isGenset.isTouched}
            change={(e) => updateForm(e.target.value, 'isGenset')}
            id='genset-input'
            label='Genset'
            inputStyle='main'
          />
        </div>

        <div className={[classes['tri-input'], 'd-md-flex'].join(' ')}>
          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.sealNumber.value}
            invalid={!formValues.sealNumber.isValid}
            shouldValidate
            touched={formValues.sealNumber.isTouched}
            change={(e) => updateForm(e.target.value, 'sealNumber')}
            id='seal-input'
            label='Seal No.'
            inputStyle='main'
          />

          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.containerNumber.value}
            invalid={!formValues.containerNumber.isValid}
            shouldValidate
            touched={formValues.containerNumber.isTouched}
            change={(e) => updateForm(e.target.value, 'containerNumber')}
            id='containerNumber-input'
            label='Container No.'
            inputStyle='main'
          />

          <Input
            elementType={'text'}
            elementConfig={{
              type: 'text',
              placeholder: '',
            }}
            value={formValues.temptaleNumber.value}
            invalid={!formValues.temptaleNumber.isValid}
            shouldValidate
            touched={formValues.temptaleNumber.isTouched}
            change={(e) => updateForm(e.target.value, 'temptaleNumber')}
            id='temptaleNumber-input'
            label='Temptale Number.'
            inputStyle='main'
          />
        </div>

        <div>
          <Input
            elementType={'textarea'}
            elementConfig={{
              type: 'textarea',
              placeholder: '',
            }}
            value={formValues.additionalInfo.value}
            invalid={!formValues.additionalInfo.isValid}
            shouldValidate
            touched={formValues.additionalInfo.isTouched}
            change={(e) => updateForm(e.target.value, 'additionalInfo')}
            id='additionalInfo-input'
            label='Additional Info.'
            inputStyle='main'
          />
        </div>

        <div className='d-flex'>
          <Input
            id="loadTime"
            elementType={'select'}
            allowEmpty={true}
            elementConfig={{
              type: 'select',
              placeholder: 'Load Time',
              options: Array.from({ length: 24 }, (_, i) => ({
                value: i.toString().padStart(2, '0') + ":00",
                displayValue: i.toString().padStart(2, '0') + ":00"
              }))
            }}
            value={formValues.loadTime.value}
            invalid={!formValues.loadTime.isValid}
            shouldValidate
            touched={formValues.loadTime.isTouched}
            change={(e) => updateForm(e.target.value, 'loadTime')}
            label="Load Time"
          />

          <div className='px-3'></div>

          <Input
            id="bookingTime"
            elementType={'select'}
            elementConfig={{
              type: 'select',
              placeholder: 'Booking Time',
              options: Array.from({ length: 24 }, (_, i) => ({
                value: i.toString().padStart(2, '0') + ":00",
                displayValue: i.toString().padStart(2, '0') + ":00"
              }))
            }}
            value={formValues.bookingTime.value}
            invalid={!formValues.bookingTime.isValid}
            shouldValidate
            touched={formValues.bookingTime.isTouched}
            change={(e) => updateForm(e.target.value, 'bookingTime')}
            label="Booking Time"
          />
        </div>

        <div className='pt-3'>
          <div
            className={
              !formValues.loadDate.isValid &&
                formValues.loadDate.isTouched
                ? [classes['load-date'], classes['invalid-load-date']].join(' ')
                : classes['load-date']
            }
          >
            <span>Load Date:</span>
            <div className='mt-2 text-center'>
              <br />
              <span>
                {formValues.loadDate.date
                  ? formValues.loadDate.date.toDateString()
                  : 'No date selected'}
              </span>
            </div>

            <DatePicker
              defaultDate={defaultLoadDate}
              maxDate={maxLoadDate}
              minDate={minLoadDate}
              callback={(date, isValid) => {
                if (isValid && date !== null) {
                  updateForm(date?.toISOString(), 'loadDate', date);
                }
              }}
              invalidMessage='Invalid date'
              autoConfirm
            />
          </div>
        </div>

        <div className={[classes.buttons, 'pt-3'].join(' ')}>
          <div className='pointer no-select' onClick={props.close}>Cancel</div>

          {isLoading ? <Loader
            size='small'
          /> : (
            <Button
              type='submit'
              buttonStyle='main'
              text='Create Load'
            />
          )}
        </div>
      </form >
    </div >
  );
};

export default CreateNewLoad;
