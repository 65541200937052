import { FC, useState } from 'react';
import classes from './DatePicker.module.scss';
import getMonthDays from './functions/getMonthDays';
import Months from './Months/Months';
import Days from './Days/Days';
import Button from '../Button/Button';

interface DatePickerProps {
  defaultDate: Date;
  maxDate?: Date;
  minDate?: Date;
  autoConfirm?: boolean;
  invalidMessage?: string;
  showClear?: boolean;
  callback: (date: Date | null, valid: boolean) => void;
}

interface DatePickerState {
  daysCount: number;
  isValid: boolean;
  date: Date;
  weekdayOne: number;
  year: number;
}

const DatePicker: FC<DatePickerProps> = (props) => {
  const calcWeekdayOne = (y: number, m: number): number => {
    let tempDate = new Date(y, m, 1);
    return tempDate.getDay();
  };

  const validate = (date: Date): boolean => {
    if (!props.maxDate && !props.minDate) return true;

    if (!props.maxDate && props.minDate) {
      if (date.getTime() > props.minDate.getTime()) return true;
    }

    if (!props.minDate && props.maxDate) {
      if (date.getTime() > props.maxDate.getTime()) return true;
    }

    return (
      date.getTime() < (props.maxDate?.getTime() || Infinity) &&
      date.getTime() > (props.minDate?.getTime() || -Infinity)
    );
  };

  let year = props.defaultDate.getFullYear();
  let month = props.defaultDate.getMonth();
  let day = props.defaultDate.getDate();
  let date = new Date(year, month, day);

  const [state, setState] = useState<DatePickerState>({
    daysCount: getMonthDays(year, month),
    isValid: validate(date),
    date: date,
    weekdayOne: calcWeekdayOne(year, month),
    year: date.getFullYear()
  });

  const setDay = (day: number): void => {
    let newDate = new Date(state.date);
    newDate.setDate(day);
    setState({
      ...state,
      date: newDate,
      isValid: validate(newDate)
    });

    if (props.autoConfirm && validate(newDate)) {
      props.callback(newDate, validate(newDate));
    }
  };

  const setYear = (year: number, month?: number): void => {
    let newDate = new Date(state.date);
    if (month !== undefined) {
      newDate.setFullYear(year, month);
    } else {
      newDate.setFullYear(year);
    }

    setState({
      ...state,
      date: newDate,
      isValid: validate(newDate),
      daysCount: getMonthDays(newDate.getFullYear(), newDate.getMonth()),
      weekdayOne: calcWeekdayOne(newDate.getFullYear(), newDate.getMonth()),
      year: year
    });

    if (props.autoConfirm && validate(newDate)) {
      props.callback(newDate, validate(newDate));
    }
  };

  const setMonth = (month: number): void => {
    let newDate = new Date(state.date);
    newDate.setMonth(month);
    setState({
      ...state,
      date: newDate,
      isValid: validate(newDate),
      daysCount: getMonthDays(newDate.getFullYear(), newDate.getMonth()),
      weekdayOne: calcWeekdayOne(newDate.getFullYear(), newDate.getMonth()),
      year: newDate.getFullYear()
    });

    if (props.autoConfirm && validate(newDate)) {
      props.callback(newDate, validate(newDate));
    }
  };

  return (
    <div className={classes['date-picker']}>
      <Months
        month={state.date.getMonth()}
        setYear={setYear}
        setMonth={setMonth}
        year={state.date.getFullYear()}
      />

      <Days
        daysCount={state.daysCount}
        setDay={setDay}
        date={state.date}
        weekdayOne={state.weekdayOne}
        isValid={state.isValid}
      />

      <div className='confirmation'>
        {!state.isValid ? <span>{props.invalidMessage}</span> : null}

        {props.showClear ? (
          <button
            className={classes['confirm']}
            onClick={() => props.callback(null, false)}
            disabled={!state.isValid}
          >
            Clear
          </button>
        ) : null}

        {!props.autoConfirm ? (
          <div className={classes['confirm-button']}>
            <Button
              type='submit'
              buttonStyle='main'
              text='Confirm'
              click={() => props.callback(state.date, validate(state.date))}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DatePicker;
