
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import classes from './Prices.module.scss';
import get from '../../../services/axios/get';
import Loader from '../../UI/Loader/Loader';
import PriceList from './PriceList/PriceList';


const Prices: React.FC = () => {
  const isMounted = useRef(true);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getClients = useCallback(
    () => {
      get(
        '/client',
        {},
        (res) => {
          if (isMounted) {
            setClients(res.data);
            setIsLoading(false)
          }
        },
        () => {
          setIsLoading(false)
        });
    },
    []
  );

  useEffect(() => {
    getClients();

    return () => {
      isMounted.current = false;
    }
  }, [getClients])

  const [selectedClient, setSelectedClient] = useState<any>(null);

  const handleClientSelect = (client: any) => {
    setSelectedClient(client);
  };

  return (
    <Container className={classes['prices-container']}>
      <Row className={classes['prices-content']}>
        <Col xs="3" className={classes['client-list-column']}>
          <div className={[classes.card, classes['clients-card']].join(' ')}>
            <div className={classes['card-header']}>Clients</div>
            <div className={classes['client-list-body']}>
              <ul className={classes['client-list']}>
                {isLoading ? <div className={classes['loader-container']}><Loader size='medium' /></div> : clients.map((client: any, index: number) => (
                  <li
                    key={client.id}
                    onClick={() => handleClientSelect(client)}
                    className={`${classes['client-item']} ${selectedClient?.id === client.id ? classes.active : ''} ${index % 2 !== 0 ? classes.even : ''}`}
                  >
                    {client.alias}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Col>
        <Col xs="9" className={classes['price-list-column']}>
          <div className={classes.card}>
            <div className={classes['card-header']}>
              {selectedClient ? `Price List for ${selectedClient.alias}` : 'Select a client'}
            </div>
            <div className={classes['price-list-body']}>
              {selectedClient ? (
                <PriceList clientName={selectedClient.alias} clientId={selectedClient.id} />
              ) : (
                <p>Please select a client to view their price list.</p>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Prices;